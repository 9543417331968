const imgStore = {
	images: [
		// // AAA
		// {
		// 	videoSrc: require('@/assets/img/AAA/AAA-Instagram-Story-Safe_Area-22-01-21-01.mp4'),
		// 	imgSrc: require('@/assets/img/bitteEinsteigen_01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Markengestaltung',
		// 	projectName: `'AAA`,
		// 	elementDescription: 'Instagram Story',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `In der Psychotherapie dient der Eisberg als Modell, um die unbewussten Anteile der Persönlichkeit darzustellen.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Um dieses Modell zu visualisieren, wird ein Foto des Praxissteines als Eisberg und viele Schichten von Papier als Symbol für das Unterbewusstsein verwendet.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Die ruhigen Bewegungen des Eisberges sind ein Hilfsmittel zur Stressreduktion und entsprechen der Zeit des meditativen Atmens. Einatmen während der Eisberg nach oben treibt, ausatmen während der Berg nach unten sinkt.`
		// 		}
		// 	]
		// },
		// {
		// 	imgSrc: require('@/assets/img/AAA/AAA-Banner-Oskar_Kokoschka_Platz-01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: `'AAA`,
		// 	elementDescription: 'Visitenkarten',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Enderlin ist eine gemeinschaft aus puristischen Designer und Architekten, die durch den Name Enderlin zu einer person abstrahiert werden.`
		// 		},
		// 	]
		// },
		// {
		// 	imgSrc: require('@/assets/img/AAA/AAA-Banner-Vordere_Zollamtsstraße-02.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: `'AAA`,
		// 	elementDescription: 'Visitenkarten',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Enderlin ist eine gemeinschaft aus puristischen Designer und Architekten, die durch den Name Enderlin zu einer person abstrahiert werden.`
		// 		},
		// 	]
		// },







		// // BAIT
		// {
		// 	imgSrc: require('@/assets/img/BAIT/BAIT-Instagram_Mockup-02.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: `'BAIT`,
		// 	elementDescription: 'Visitenkarten',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Enderlin ist eine gemeinschaft aus puristischen Designer und Architekten, die durch den Name Enderlin zu einer person abstrahiert werden.`
		// 		},
		// 	]
		// },
		// {
		// 	imgSrc: require('@/assets/img/BAIT/BAIT-Instagram_Mockup-03.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: `'BAIT`,
		// 	elementDescription: 'Visitenkarten',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Enderlin ist eine gemeinschaft aus puristischen Designer und Architekten, die durch den Name Enderlin zu einer person abstrahiert werden.`
		// 		},
		// 	]
		// },
		// {
		// 	imgSrc: require('@/assets/img/BAIT/BAIT-Instagram_Mockup-06.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: `'BAIT`,
		// 	elementDescription: 'Visitenkarten',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Enderlin ist eine gemeinschaft aus puristischen Designer und Architekten, die durch den Name Enderlin zu einer person abstrahiert werden.`
		// 		},
		// 	]
		// },
		// {
		// 	imgSrc: require('@/assets/img/BAIT/BAIT-Instagram_Mockup-07.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: `'BAIT`,
		// 	elementDescription: 'Visitenkarten',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Enderlin ist eine gemeinschaft aus puristischen Designer und Architekten, die durch den Name Enderlin zu einer person abstrahiert werden.`
		// 		},
		// 	]
		// },



		// // THE ESSENCE
		{
			imgSrc: require('@/assets/img/The_Essence_2018-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Postergestaltung',
			projectName: 'The Essence 2018',
			elementDescription: '»Mach’ nen Knoten rein.«',
			meta: [
				{
					type: 'text',
					text: `Die Jahresausstellung der Universität für Angewandte Kunst ›THE ESSENCE‹ macht durch ihre progressiven Exponate und Studentenarbeiten immer wieder Statements zu wichtigen Themen der Gesellschaft.`
				},
				{
					type: 'text',
					text: `Als Symbol für kriegerische Handlungen wird eine Rakete verknotet, entschärft und als Readymade in einen künstlich weißen und hell beleuchteten Raum gestellt. So wird gezeigt, dass kriegerisches Handeln zur Geschichte gehört und nur noch einen Platz im Museum hat.`
				},
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/The_Essence_2018-Dokumentation-Mockup-Poster-180901-01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Postergestaltung',
		// 	projectName: 'The Essence 2018',
		// 	elementDescription: '»All The Same«',

		// },
		{
			imgSrc: require('@/assets/img/The_Essence_2018-DU-Mockup-Poster-180901-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Postergestaltung',
			projectName: 'The Essence 2018',
			elementDescription: 'Kontrollverlust',
			meta: [
				{
					type: 'text',
					text: `In Zeiten von Fake-News und Boulevard-Presse werden Medien oft zweckentfremdet.`
				},
				{
					type: 'text',
					text: `Ohne es zu wissen, wird der Betrachter zum Ziel von manipulativen Tricks und Lügen.`
				},
				{
					type: 'text',
					text: `Auf schonungslose Weise macht ›THE ESSENCE‹ auf den Missbrauch von Medien aufmerksam, und wirkt zugleich mit verantwortungsvollen Exponaten entgegen.`,
				}
			]
		},
		{
			imgSrc: require('@/assets/img/AesthetikDerVeraenderungJugenstil_01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Postergestaltung',
			projectName: 'Ästhetik der Veränderung',
			elementDescription: '',
			meta: [
				{
					type: 'text',
					text: `Zum Anlass ihres 150-jährigen Bestehens veranstaltet die Universität für angewandte Kunst Wien eine Ausstellung über ihre Geschichte, Gegenwart und Zukunft.`
				},
				{
					type: 'text',
					text: `Das Motto der Ausstellung – Ästhetik der Veränderung – ist ein Andeutung auf den stetigen Wandel der Institution.`
				},
				{
					type: 'text',
					text: `Dieser Wandel wird auf moderne Art durch das Verquicken der Typografie visualisiert, die den Ursprung der Universität aus der Zeit des Jugendstils zitiert.`
				}
			]
		},



		// // Ferdinand sucht arbeit
		{
			imgSrc: require('@/assets/img/FerdinandSuchtArbeit01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Aufmerksamkeit und Jobvermittlung für Obdachlose',
			projectName: '#MenschSuchtArbeit #FerdinandSuchtArbeit',
			elementDescription: 'Wohnungsloser Ferdinand mit seinem menschengroßen Kartonlebenslauf',
			meta: [
				// {
				// 	type: 'link',
				// 	linkText: 'Mehr Erfahren',
				// 	text: `Eine Initiative zur Vermittlung Obdach- und Wohnungsloser an ArbeitgeberInnen.`,
				// }
				{
					type: 'text',
					text: `Obdachlose sind Individuen, die das Vertrauen von der Gesellschaft und in die Gesellschaft verloren haben, und deren Chance auf ein selbstständig verdientes Einkommen oft angezweifelt wird.`,
				},
				{
					type: 'text',
					text: `#MenschSuchtArbeit ist eine kommunikative Maßnahme gegen das abwertende Stereotypisieren von Obdach- und Wohnungslosen.`,
				},
				{
					type: 'text',
					text: `Dabei vermitteln Bewerbungen in Form von Kartonschildern den Werdegang und die Berufsqualifikationen der Teilnehmenden.`,
				},
				{
					type: 'text',
					text: `Die Schilder brechen die negative Erwartungshaltung der Mitmenschen, sind ehrlich und verhelfen zu Aufmerksamkeit und dadurch zum Ziel des Wiedereintritts in ein Berufsleben.`,
				}
			]
		},
		{
			imgSrc: require('@/assets/img/FerdinandSuchtArbeit02.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Aufmerksamkeit und Jobvermittlung für Obdachlose',
			projectName: '#MenschSuchtArbeit #FerdinandSuchtArbeit',
			elementDescription: 'Ferdinand auf Jobsuche',
			meta: [
				{
					type: 'text',
					text: `In den vier Stunden, in denen Ferdinand mit seinem Schild auf der Wiener Mariahilferstraße gestanden hat, wurde er von zwei Journalisten angesprochen, die seine Geschichte veröffentlicht haben.`,
				},
				{
					type: 'text',
					text: `#MenschSuchtArbeit und #FerdinandSuchtArbeit wurden seit dem ersten Fotoposting am 13. Juni 2017 über 2395 Mal geteilt.`,
				},
				{
					type: 'text',
					text: `Mithilfe seines Schildes und der daraus resultierenden Aufmerksamkeit wurden Ferdinand 12 Jobmöglichkeiten vermittelt.`,
				}
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/FerdinandSuchtArbeit03.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Aufmerksamkeit und Jobvermittlung für Obdachlose',
		// 	projectName: '#MenschSuchtArbeit #FerdinandSuchtArbeit',
		// 	elementDescription: 'Wohnungsloser Ferdinand mit seinem menschengroßen Kartonlebenslauf',

		// },




		// // OKEY
		// {
		// 	imgSrc: require('@/assets/img/Okey-Branding-Zeitungsinserat-01-2019-06-11-01.jpg'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'Produktdesign und Branding',
		// 	projectName: 'Okey – The One Hand Keyboard',
		// 	elementDescription: 'Zeitungsinserat',

		// },
		// {
		// 	imgSrc: require('@/assets/img/Okey-Mockup-Billboard-01-2019-06-11-01.jpg'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'Produktdesign und Branding',
		// 	projectName: 'Okey – The One Hand Keyboard',
		// 	elementDescription: 'Werbeplakt 24-Bogen',
		// 	meta: [
		// 		{
		// 			type: 'link',
		// 			linkText: 'Mehr Erfahren',
		// 			text: `Sujet in Anwendung auf 24-Bogen Plakat.`
		// 		}
		// 	]
		// },
		{
			imgSrc: require('@/assets/img/Okey-Mockup-Billboard-02-2019-06-11-01.jpg'),
			squareCorners: true,
			isWebsite: false,
			task: 'Produktdesign und Branding',
			projectName: 'Okey – The One Hand Keyboard',
			elementDescription: 'Werbeplakt 48-Bogen',
			meta: [
				{
					type: 'text',
					text: `Um die Freiheit und Distanz zwischen Tastatur- und Maushand zu zeigen, nutzt Okey ein breites 48-Bogen-Langform-Plakat.`
				}
			]
		},
		{
			imgSrc: require('@/assets/img/Okey-Rendering-Topview.jpg'),
			squareCorners: true,
			isWebsite: false,
			task: 'Produktdesign und Branding',
			projectName: 'Okey – The One Hand Keyboard',
			elementDescription: 'Produkt',
			meta: [
				{
					type: 'text',
					text: `Okey ist eine super kleine, extrem schreibeffiziente Einhandtastatur mit allen Funktionen einer konventionellen Tastatur.`
				},
				{
					type: 'text',
					text: `Um die ideale Tastenanordnung für die deutsche Sprache zu entwickeln, haben wir die häufigsten Buchstabenkombinationen sprachwissenschaftlich untersucht und in angrenzenden Tasten platziert.`
				},
				{
					type: 'text',
					text: `Die Tasten wurden – inspiriert von den Bienenwaben – hexagonal angeordnet. Diese Anordnung stellt die beste Proportion zwischen runder Grundfläche des menschlichen Fingers und der Anzahl angrenzender Tasten dar.`
				}
			]
		},
		{
			imgSrc: require('@/assets/img/Okey-Rendering-Hand.jpg'),
			squareCorners: true,
			isWebsite: false,
			task: 'Produktdesign und Branding',
			projectName: 'Okey – The One Hand Keyboard',
			elementDescription: 'Produkt in Gebrauch',
			meta: [
				{
					type: 'text',
					text: `Ist dir jemals aufgefallen, dass bei der Arbeit am Computer drei Hände gebraucht werden?…`
				},
				{
					type: 'text',
					text: `Zwei für die Tastatur und eine für die Maus.`
				},
				{
					type: 'text',
					text: `Okey löst dieses Problem durch die komplette Neugestaltung der Tastatur für den Gebrauch mit einer Hand, ohne auf Funktionen verzichten zu müssen.`
				}
			]
		},







		// VIENNA.JS
		// {
		// 	imgSrc: require('@/assets/img/vienna-js.png'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'Branding',
		// 	projectName: 'Vienna.js',
		// 	elementDescription: 'Logo für Vienna.js',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Vienna.js ist ein Meetup der Wiener Javascript- und Webentwicklerszene.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Da Javascript und die meisten anderen Programmiersprachen meist mit sogennanten Monospace Schriften geschrieben wird, benutzen wir eine solche als Grundlage der Logogestaltung.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Um den Wiener Usrpung des Meetups zu verdeutlichen, haben wir die Schrift so umgestaltet, dass sie an die typischen Wiener Geschäftslokalschriftzüge erinnert.`
		// 		},
		// 	]
		// },
		// {
		// 	videoSrc: require('@/assets/img/vienna_js-sponsor-01.mp4'),
		// 	imgSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Instagram-Story-01-180805-01.png'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'Branding',
		// 	projectName: 'Vienna.js',
		// 	elementDescription: 'Sponsorenbadge Vorschlag',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Vienna.js ist ein Meetup der Wiener Javascript- und Webentwicklerszene.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Da Javascript und die meisten anderen Programmiersprachen meist mit sogennanten Monospace Schriften geschrieben wird, benutzen wir eine solche als Grundlage der Logogestaltung.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Um den Wiener Usrpung des Meetups zu verdeutlichen, haben wir die Schrift so umgestaltet, dass sie an die typischen Wiener Geschäftslokalschriftzüge erinnert.`
		// 		},
		// 	]
		// },






		// // OSKAR NIMMT PLATZ Buch
		{
			imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Cover-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Buchgestaltung',
			projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
			elementDescription: 'Buchcover',
			meta: [
				{
					type: 'text',
					text: `Die Eindrücke der einwöchigen Nutzung des Oskar-Kokoschka-Platzes zu künstlerischen Zwecken wurden zu Dokumentationszwecken in einem Buch festgehalten.`
				},
				{
					type: 'text',
					text: `Das Cover nutzt dafür die Corporate Design-Elemente von OSKAR NIMMT PLATZ.`
				},
				{
					type: 'text',
					text: `Ein letztes O, das im Corporate Design von OSKAR NIMMT PLATZ die vielen Besucher des Platzes symbolisiert, verlässt das Cover und lässt nur den Schriftzug als Erinnerung an das Event stehen.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Pagination-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Buchgestaltung',
			projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
			elementDescription: 'Pagination',
			meta: [
				{
					type: 'text',
					text: `Das Buch ist – wie das Event – in Tage gegliedert. Die einzelnen Tage lassen sich im Buch schnell durch die am Rand gedruckte Seitennummerierung finden.`
				}
			]
		},
		{
			imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Programm-02.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Buchgestaltung',
			projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
			elementDescription: 'Programmüberischt',
			meta: [
				{
					type: 'text',
					text: `Beim Durchblättern des Buches wird durch die stetige Platzierung der Eventfotos auf der rechten Seite schnell ein visueller Eindruck des Events vermittelt.`
				}
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Zeitplan-02.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Buchgestaltung',
		// 	projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
		// 	elementDescription: 'Zeitachse eines Tagesprogrammes',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Meta Entry one`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Second meta Entry`
		// 		},
		// 	]
		// },

		// GRAFIKEN
		{
			imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Dokumentation-Zeitleiste-01-2020-05-01-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Buchgestaltung',
			projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
			elementDescription: 'Zeitachse eines Tagesprogrammes',
			meta: [
				{
					type: 'text',
					text: `Das Programm jedes Tages wird durch einen Zeitstrahl visualisiert.`
				},
				{
					type: 'text',
					text: `Die Programmarten ›Laufendes Programm‹ und ›Tagesprogramm‹ werden durch ihre Farbgebunde unterschieden.`
				},
			]
		},

		// {
		// 	imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Dokumentation-Programm-02-2020-05-01-01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Buchgestaltung',
		// 	projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
		// 	elementDescription: 'Programmüberischt',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Meta Entry one`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Second meta Entry`
		// 		},
		// 	]
		// },

		{
			imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentationsbuch/OSKAR_NIMMT_PLATZ-Dokumentationsbuch-Dokumentation-Tagestitel-01-2020-05-01-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Buchgestaltung',
			projectName: 'OSKAR NIMMT PLATZ Dokumentationsbuch',
			elementDescription: 'Tagesthema',
			meta: [
				{
					type: 'text',
					text: `Das Gestaltungselement der Typografie (der Schrift) bringt mit ihrer Modernisierung formaler Elemente aus der Zeit Oskar Kokoschkas eine frische Perspektive in unser Design.`
				},
				{
					type: 'text',
					text: `Sie ehrt nicht nur das Erbe eines großen Künstlers, sondern spricht auch ein zeitgenössisches Publikum an, indem sie Tradition und Moderne gekonnt verbindet.`
				}
			]
		},






		// // OSKAR NIMMT PLATZ Brand
		{
			imgSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Facebook_Shared-Handy-01-180821-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Corporate Design',
			projectName: 'OSKAR NIMMT PLATZ',
			elementDescription: 'Facebook-Veranstaltungsbild',
			meta: [
				{
					type: 'text',
					text: `Bei der Gestaltung eines Corporate Designs ist wichtig, dass  Sichtbarkeit und Wiedererkennung über verschiedene Medien hinweg gewährleistet werden.`
				},
				{
					type: 'text',
					text: `Das dynamische Design von OSKAR NIMMT PLATZ demonstriert dies perfekt, indem es sich durch eine Neuanordnung der 'O' für alle Formate adaptieren lässt.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/OskarNimmtPlatz-Foto-Transparent-01-180709-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Corporate Design',
			projectName: 'OSKAR NIMMT PLATZ',
			elementDescription: 'Banner',
			meta: [
				{
					type: 'text',
					text: `OSKAR NIMMT PLATZ ist eine Veranstaltungsreihe mit einwöchiger Nutzung des Oskar-Kokoschka-Platzes zu künstlerischen Zwecken.`
				},
				{
					type: 'text',
					text: `Das Corporate Design für den Event ist ein modernisiertes Zitat der Ästhetik des Namensgebers und Kunstikone Oskar Kokoschka.`
				},
				{
					type: 'text',
					text: `Dafür wird das 'O' von Oskar vervielfältigt, und als Symbol für die vielen Studenten, Künstler und Besucher des Platzes genutzt. So wird es zum dynamischen Element des Designs, das die Bewegung auf dem Platz verbildlicht.`
				},
				// CHAT-GPT AB HIER
				// CHAT-GPT AB HIER
				// CHAT-GPT AB HIER
				// CHAT-GPT AB HIER
				// CHAT-GPT AB HIER
				{
					type: 'text',
					text: `Durch eine Neuanordnung der 'O' kann das Design an jedes beliebige Format angepasst werden und bleibt trotzdem frisch."`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Dokumentation-Inserat-Falter-01-180803-FullRes.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Corporate Design',
			projectName: 'OSKAR NIMMT PLATZ',
			elementDescription: 'Zeitungsinserat im ›Der Falter‹',
			meta: [
				{
					type: 'text',
					text: `Bei der Gestaltung eines Corporate Designs ist wichtig, dass  Sichtbarkeit und Wiedererkennung über verschiedene Medien hinweg gewährleistet werden.`
				},
				{
					type: 'text',
					text: `Das dynamische Design von OSKAR NIMMT PLATZ demonstriert dies perfekt, indem es sich durch eine Neuanordnung der 'O' für alle Formate adaptieren lässt.`
				},
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Poster-Allein-01-180708-01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: 'OSKAR NIMMT PLATZ',
		// 	elementDescription: 'Poster',

		// },
		// {
		// 	imgSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Poster-Wand-01-180708-01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: 'OSKAR NIMMT PLATZ',
		// 	elementDescription: 'Poster',

		// },
		// {
		// 	imgSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Facebook-Handy-01-180709-01.jpg'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: 'OSKAR NIMMT PLATZ',
		// 	elementDescription: 'Facebook-Seite mit grafischen Elementen',

		// },
		// {
		// 	imgSrc: require('@/assets/img/OSKAR_NIMMT_PLATZ-Screenshot-Webseite-01-180805-01.png'),
		// 	squareCorners: true,
		// 	isWebsite: true,
		// 	task: 'Webdesign und Programmierung',
		// 	projectName: 'OSKAR NIMMT PLATZ',
		// 	elementDescription: 'www.oskarnimmtplatz.org',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Meta Entry one`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Second meta Entry`
		// 		},
		// 	]
		// },
		{
			videoSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Instagram-Story-01-180805-01.mp4'),
			imgSrc: require('@/assets/img/OskarNimmtPlatz-Mockup-Instagram-Story-01-180805-01.png'),
			squareCorners: false,
			isWebsite: false,
			task: 'Corporate Design',
			projectName: 'OSKAR NIMMT PLATZ',
			elementDescription: 'Animierte Instgram-Story',
			meta: [
				{
					type: 'text',
					text: `In animierten Varianten wird die Dynamik des Designs durch die Bewegung der 'O' verstärkt, wodurch der Eindruck einer belebten Menschenansammlung entsteht.`
				}
			]
		},
		// {
		// 	videoSrc: require('@/assets/img/OskarNimmtPlatz-Video-Transparent-01-180731.mp4'),
		// 	imgSrc: require('@/assets/img/OskarNimmtPlatz-Video-Transparent-01-180731.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Design',
		// 	projectName: 'OSKAR NIMMT PLATZ',
		// 	elementDescription: 'Banner',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Meta Entry one`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Second meta Entry`
		// 		},
		// 	]
		// },





		// // Inhale/Exhale
		{
			imgSrc: require('@/assets/img/Inhale_Exhale-Dokumentation-Foto-Technoid-01-180902-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'CD-Gestaltung',
			projectName: 'Inhale/Exhale',
			elementDescription: 'Cover',
			meta: [
				{
					type: 'text',
					text: `Das Album Inhale/Exhale der Post-Rock-Band <i>Sonic. The Machine</i> wird als leise bis laut und dreckig bis schön beschrieben.`
				},
				{
					type: 'text',
					text: `Dystopische Illustrationen für das Cover und das Booklet fangen die Stimmung des Albums ein.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/Inhale_Exhale-Dokumentation-Foto-Technoid-02-180902-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'CD-Gestaltung',
			projectName: 'Inhale/Exhale',
			elementDescription: 'Songliste',
			meta: [
				{
					type: 'text',
					text: `Inhale`
				},
				{
					type: 'text',
					text: `Exhale`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/Inhale_Exhale-Dokumentation-Foto-Technoid-03-180902-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'CD-Gestaltung',
			projectName: 'Inhale/Exhale',
			elementDescription: 'CD mit Booklet',
			meta: [
				{
					type: 'text',
					text: `Inhale`
				},
				{
					type: 'text',
					text: `Exhale`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/Inhale_Exhale-Dokumentation-Foto-Technoid-04-180902-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'CD-Gestaltung',
			projectName: 'Inhale/Exhale',
			elementDescription: 'CD mit Booklet',
			meta: [
				{
					type: 'text',
					text: `Inhale`
				},
				{
					type: 'text',
					text: `Exhale`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/Inhale_Exhale-Dokumentation-Foto-Technoid-05-180902-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'CD-Gestaltung',
			projectName: 'Inhale/Exhale',
			elementDescription: 'Cover',
			meta: [
				{
					type: 'text',
					text: `Inhale`
				},
				{
					type: 'text',
					text: `Exhale`
				},
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/inhale-exhale_03.jpg'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'CD-Gestaltung',
		// 	projectName: 'Inhale/Exhale',
		// 	elementDescription: 'Hülle Innenseite',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Meta Entry one`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Second meta Entry`
		// 		},
		// 	]
		// },
		// {
		// 	imgSrc: require('@/assets/img/inhaleExhale_01.jpg'),
		// 	squareCorners: true,
		// 	isWebsite: false,
		// 	task: 'CD-Gestaltung',
		// 	projectName: 'Inhale/Exhale',
		// 	elementDescription: 'Hülle Außenseite',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Meta Entry one`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Second meta Entry`
		// 		},
		// 	]
		// },







		// // IVAN
		// {
		// 	textSrc: `
		// 		100% E N E R G I E. Hüpfen, klettern, laufen, kriechen, rollen und am Liebsten gleich fliegen. Kinder lieben Bewegung und sind wahre Sportskanonen. Aber anstatt sich körperlich auszupowern, sitzen sie (wie wir) den ganzen Tag und drücken die Schulbank.
		// 		<br><br>
		// 		Nicht mit mir! Gemeinsam machen wir aus jedem Spielplatz einen Hindernisparcours! Aus Hampelmännern werden Aufwärmübungen und aus wilder Rennerei richtiger Ausdauersport. Spielerisch trainieren wir die vom Sitzen belasteten Körper und machen sie fit für den stressigen Lernalltag. Auf die Plätze, fertig, LOS!
		// 	`,
		// 	task: 'Branding',
		// 	projectName: 'Ivan',
		// 	elementDescription: 'Text für Kindertraining',
		// 	descriptionIsVisible: true,

		// },
		// {
		// 	imgSrc: require('@/assets/img/IVAN-TEST-02.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Branding',
		// 	projectName: 'Ivan',
		// 	elementDescription: 'Mobile Webseite, www.ivan.fit',
		// },
		{
			imgSrc: require('@/assets/img/IVAN-Shooting-Kids_Running-20180416-Cropped-1920h.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Branding',
			projectName: 'Ivan',
			elementDescription: 'Imagefoto',
			meta: [
				{
					type: 'text',
					text: `Durch eine Marktanalyse sind wir zu der Erkenntnis gekommen, dass Bewegung im Alltag von Kindern deutlich abnimmt, obwohl gerade sie einen großen Bewegungsdrang haben.`
				},
				{
					type: 'text',
					text: `Anstatt sich körperlich auszupowern, sitzen Kinder (wie wir auch) den ganzen Tag und drücken die Schulbank…`
				},
				{
					type: 'text',
					text: `Unsere Analyse hat deutlich gemacht, dass ein spielerisches Training nicht nur essentiell für die körperliche Entwicklung ist, sondern auch perfekt zum Kern von IVANS Marke passt – Bewegung mit Freude zu verbinden.`
				},
				{
					type: 'text',
					text: `Deshalb haben wir gemeinsam mit IVAN ein spielerisches Kindertraining-Angebot entwickelt, in dem die vom Sitzen belasteten Kinder in ihrer Haltung gestärkt und fit gemacht werden.`
				}
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-FunFacts-01.png'),
			squareCorners: false,
			isWebsite: false,
			task: 'Branding',
			projectName: 'Ivan',
			elementDescription: 'Text für Fun Facts und Social Media.',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Ein Teil von IVANs Marke sind spaßige Fakten, die die Notwendigkeit eines sportlichen Ausgleichs im Alltag verdeutlichen.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-Shooting-Funny_Portrait-20180416-WebRes-1920h.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Branding',
			projectName: 'Ivan',
			elementDescription: 'Imagefoto',
			meta: [
				{
					type: 'text',
					text: `Als Testimonial für IVANs Trainingserfolge dient unter anderem er selbst. 'Feel Good' ist ein Teil seiner DNA.`
				}
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-Foto-Opne_Air_Gym-Kids_Training-180630-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Branding',
			projectName: 'Ivan',
			elementDescription: 'Imagefoto',
			meta: [
				{
					type: 'text',
					text: `Um einen konstanten Look zu gewährleisten, greifen wir das Farbkonzept der Marke IVAN auch in den Imagefotos auf.`
				},
			]
		},
		{
			videoSrc: require('@/assets/img/IVAN_FlaggeRendering_InstagramStory_cube.mp4'),
			imgSrc: require('@/assets/img/IVAN_FlaggeRendering_InstagramStory_cube.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Branding',
			projectName: 'Ivan',
			elementDescription: 'Logogestaltung',
			meta: [
				{
					type: 'text',
					text: `IVANs Herkunft aus dem Balkan prägt seinen Charakter und auch die Marke. Die Markenfarben Rot, Blau und Weiß sind von der kroatischen Flagge abgeleitet.`
				},
				{
					type: 'text',
					text: `Auch die Logotype leitet sich von dem brutalistischen Stil des ehemaligen Jugoslawiens ab und setzt gleichzeitig ein kühnes Statement, passend zu einer Fitnessmarke.`
				},
				{
					type: 'text',
					text: `In Anlehnung an den Markenleitsatz 'Get Fit & Feel Great' wird die formale Schwere der Buchstaben durch die Dynamik des schrägen 'V' und 'A' ausgeglichen.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-Dokumentation-Webseite_Screenshots-Gruppen_Training-01-180713-01.jpg'),
			squareCorners: true,
			isWebsite: true,
			task: 'Webdesign und Programmierung',
			projectName: 'Ivan',
			elementDescription: 'www.ivan.fit',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Unsere Umfeldanalyse hat ergeben, dass gemeinschaftliches Trainieren motivationssteigernd ist.`
				},
				{
					type: 'text',
					text: `Basierend auf dieser Analyse haben wir gemeinsam mit IVAN das Angebot für Gruppentraining entwickelt. Dabei teilen die Kunden nicht nur die Kosten, sondern auch die Freude mit Freunden, die sich gegenseitig anfeuern.`
				}
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-Dokumentation-Webseite_Screenshots-Kontakt-01-180713-01.jpg'),
			squareCorners: true,
			isWebsite: true,
			task: 'Webdesign und Programmierung',
			projectName: 'Ivan',
			elementDescription: 'www.ivan.fit',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Verschiedene Kontaktmöglichkeiten und ein Formular bieten potenziellen Kunden die Möglichkeit, sich über seine Website mit IVAN in Verbindung zu setzen.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-Dokumentation-Webseite_Screenshots-Start-01-180713-01.jpg'),
			squareCorners: true,
			isWebsite: true,
			task: 'Webdesign und Programmierung',
			projectName: 'Ivan',
			elementDescription: 'www.ivan.fit',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `IVANs Website ist durch grafisches Farbblocking in Sektionen unterteilt.`
				},
			]
		},
		{
			imgSrc: require('@/assets/img/IVAN-Dokumentation-Porezessskizzen-Claim-01-180907-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Branding',
			projectName: 'Ivan',
			elementDescription: 'Key Values',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Aus unserem markenstrategischen Workshop sind die Grundwerte der Marke IVAN hervorgegangen.`
				},
				{
					type: 'text',
					text: `Auf der einen Seite stehen Disziplin und harte Arbeit, die jedes Training voraussetzt – 'Get Fit'.`
				},
				{
					type: 'text',
					text: `Auf der anderen Seite stehen der Spaß, die positiven Effekte guter Ernährung und die aus dem Training resultierende gute Laune – 'Feel Great'.`
				},
				{
					type: 'text',
					text: `Aus diesen beiden Teilen haben die den Leitsatz 'Get Fit & Feel Great' zusammengesetzt.`
				},
			]
		},







		// // PRAXIS KOLLEKTIV WOLLZEILE
		{
			videoSrc: require('@/assets/img/Praxis_Kollektiv_Wollzeile/Praxis_Kollektiv_Wollzeile-Dokumentation-Key_Visual-01.mp4'),
			imgSrc: require('@/assets/img/Praxis_Kollektiv_Wollzeile/Praxis_Kollektiv_Wollzeile-Dokumentation-Key_Visual-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Corporate Identity',
			projectName: 'PRAXIS KOLLEKTIV WOLLZEILE',
			elementDescription: 'Key Visual',
			meta: [
				{
					type: 'text',
					text: `Der Eisberg wird in der Psychotherapie oft als Modell herangezogen, um die unbewussten Anteile der Persönlichkeit zu illustrieren.`
				},
				{
					type: 'text',
					text: `Um dieses Modell zu visualisieren, verwenden wir ein Foto des Praxissteines, das einen Eisberg darstellt, und viele Schichten von Papier als Symbol für das Unterbewusstsein.`
				},
				{
					type: 'text',
					text: `Die ruhigen Bewegungen des Eisberges dienen als Hilfsmittel zur Stressreduktion und begleiten durch die Phasen des meditativen Atmens: Einatmen, während der Eisberg nach oben treibt, und Ausatmen, während der Berg nach unten sinkt.`
				}
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/Praxis_Kollektiv_Wollzeile/Praxis_Kollektiv_Wollzeile-Dokumentation-Webseite-Kollektiv_beitreten-01.png'),
		// 	squareCorners: true,
		// 	isWebsite: true,
		// 	task: 'Corporate Identity',
		// 	projectName: 'PRAXIS KOLLEKTIV WOLLZEILE',
		// 	elementDescription: 'www.praxiskollektivwollzeile.at',
		// 	descriptionIsVisible: true,
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Die Zimmer Graustein und Kronjuwel werden von den Mitgliedern des PRAXIS KOLLEKTIV WOLLZEILE gemeinschaftlich genutzt.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Die Einladung zum Beitritt in die Gemeinschaft hat die Optik eines handschriftlichen Briefes  – Inspiriert durch Freuds regen Briefaustausch mit Zeitgenossen und seinen vielen handschriftlichen Notizen.`
		// 		},
		// 	]
		// },
		{
			imgSrc: require('@/assets/img/Praxis_Kollektiv_Wollzeile/Praxis_Kollektiv_Wollzeile-Dokumentation-Kleine_Lagekarte-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Corporate Identity',
			projectName: 'PRAXIS KOLLEKTIV WOLLZEILE',
			elementDescription: 'Kleine Lagekarte',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Das PRAXIS KOLLEKTIV WOLLZEILE ist eine künstlerisch gestaltete Praxis im Herzen der inneren Stadt.`
				},
				{
					type: 'text',
					text: `In den Ruhezeiten erholen sich die Patienten im Stadtpark, genießen eine Melange im Café Prückel oder reflektieren im Museum für angewandte Kunst (MAK) über ihre Entwicklung im PRAXIS KOLLEKTIV WOLLZEILE.`
				},
				{
					type: 'text',
					text: `Unsere künstlerisch illustrierte Karte dient Besuchern zur Orientierung in der Umgebung. Der Praxisstein markiert darin die Lage der Praxis.`
				},
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/Praxis_Kollektiv_Wollzeile/Praxis_Kollektiv_Wollzeile-Dokumentation-Logo-01.jpg'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Corporate Identity',
		// 	projectName: 'PRAXIS KOLLEKTIV WOLLZEILE',
		// 	elementDescription: 'Logo',
		// 	descriptionIsVisible: true,
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Ein großer Stein, gefunden bei einem Strandspaziergang, dient formgebend für die Logogestaltung des PRAXIS KOLLEKTIV WOLLZEILE.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Das Freudsche Eisbergmodell verleiht dem Logo eine tiefergehende Ebene.`
		// 		},
		// 	]
		// },








		// // AIL
		{
			imgSrc: require('@/assets/img/ail/ail-explore-01.png'),
			squareCorners: false,
			isWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Explore Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Der AIL Webraum präsentiert eine Vielzahl von Informationen im kulturellen Kontext.`
				},
				{
					type: 'text',
					text: `Das Erlebnis beim Besuch der Explore-Seite lädt – ähnlich wie das Öffnen einer Schublade – zum Entdecken ein.`
				},
				{
					type: 'text',
					text: `Durch das verschachtelte Layout, die unterschiedlichen Bildgrößen und die individuelle Farbvergabe werden traditionelle grafische Wertungen aufgebrochen.					`
				},
				{
					type: 'text',
					text: `Ein Griff in die Schublade bringt Informationsschätze zum Vorschein.`
				}
			]
		},

		{
			imgSrc: require('@/assets/img/ail/ail-home-01.png'),
			squareCorners: false,
			isWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Home Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Kreativität ist,  wenn scheinbar Zusammenhangloses in einen außergewöhnlichen Kontext gestellt wird. Diese Leitidee bildet die gestalterische Grundlage des neuen AIL Webraums.`
				},
				{
					type: 'text',
					text: `Auf der Startseite entstehen diese kreativen Zusammenhänge durch die immer neue Bespieglung der <i>›Posterwand‹</i>.`
				}
			]
		},

		{
			imgSrc: require('@/assets/img/ail/AIL-Topic-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Topic',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Ähnlich wie bei neuronalen Netzen, in denen Wissen durch die Verknüpfung von Synapsen entsteht, fungieren das Topics-Modul im AIL Webraum.`
				},
				{
					type: 'text',
					text: `Die Topics schnüren vorhandene Beiträge unter einem gemeinsamen Thema zu neuen und spannenden Informationspaketen.`
				},
				{
					type: 'text',
					text: `Mithilfe der Topics kann das AIL Team schnell auf aktuelle Ereignisse reagieren, und Mikroausstellungen in ihrem Webraum kuratieren.`
				}
			]
		},


		{
			imgSrc: require('@/assets/img/ail/ail-program-01.png'),
			squareCorners: false,
			isWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Program Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Das AIL bietet dem Austausch von Kunst und Wissenschaft virtuellen und physischen Raum.`
				},
				{
					type: 'text',
					text: `Die Nutzung dieses Raumes erfolgt in Form verschiedener Events. Die Programmseite sammelt alle diese Veranstaltungen und bietet den Besucher*innen eine übersichtliche Darstellung.`
				}
			]
		},

		{
			imgSrc: require('@/assets/img/ail/ail-participate-01.png'),
			squareCorners: false,
			isWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Participate Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Die Aufgabe des AIL ist es, die Vernetzung von Kunst, Wissenschaft und künstlerischer Forschung als treibenden Motor der Wirtschaft weiter zu etablieren.`
				},
				{
					type: 'text',
					text: `Der Aufruf der Participate-Seite dient der Förderung neuer Projekte, die im Kontext des AIL entstehen, florieren und sich präsentieren sollen.`
				}
			]
		},

		{
			imgSrc: require('@/assets/img/ail/ail-about.png'),
			squareCorners: false,
			isWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'About Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Das komplexe Geschehen im AIL wird auf der About-Seite – im Kontrast zu den anderen Seiten – durch eine minimale Farbgebung und starke Typografie dargestellt.`
				}
			]
		},

		{
			videoSrc: require('@/assets/img/ail/AIL-Website-Mobile-Navigation.mp4'),
			imgSrc: require('@/assets/img/ail/AIL-Website-Mobile-Navigation.jpg'),
			squareCorners: false,
			isWebsite: false,
			isMobileWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Mobile Navigation',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Um die Bedienung auf mobilen Endgeräten zu optimieren, ist die Navigation des AIL-Webraums benutzerfreundlich im unteren Bereich des Screens platziert.`
				}
			]
		},

		{
			videoSrc: require('@/assets/img/ail/AIL-Website-Mobile-Program.mp4'),
			imgSrc: require('@/assets/img/ail/AIL-Website-Mobile-Program.jpg'),
			squareCorners: false,
			isWebsite: false,
			isMobileWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Mobile Programm Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Um die bestmögliche Übersicht der vielen Programmpunkte des AIL auf mobilen Endgeräten zu bieten, sind die wichtigsten Informationen auf den ersten Blick ersichtlich.`
				},
				{
					type: 'text',
					text: `Bei Interesse der Besucher*innen sind zusätzliche Informationen durch horizontales Scrollen schnell erreichbar.`
				}
			]
		},

		{
			videoSrc: require('@/assets/img/ail/AIL-Website-Mobile-Search.mp4'),
			imgSrc: require('@/assets/img/ail/AIL-Website-Mobile-Search.jpg'),
			squareCorners: false,
			isWebsite: false,
			isMobileWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Suchfunktion',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Die Suchfunktion des AIL-Webraums schlägt in Echtzeit die passendsten Beiträge zum Suchbegriff vor.`
				},
				{
					type: 'text',
					text: `Durch die Verwendung von Kategorien kann die Suche weiter spezifiziert werden.`
				}
			]
		},

		{
			videoSrc: require('@/assets/img/ail/AIL-Website-Topics-Detailspage.mp4'),
			imgSrc: require('@/assets/img/ail/AIL-Website-Topics-Detailspage.jpg'),
			squareCorners: false,
			isWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: 'Topic Detailseite',
			meta: [
				{
					type: 'text',
					text: `Die in einen neuen Kontext gestellten Beiträge eines Topics – eines von AIL kuratierten Informationsbündels – werden auf der Topic-Detailseite für die Besucher*innen zugänglich und übersichtlich präsentiert.`
				},
				{
					type: 'text',
					text: `Zur Orientierung und Differenzierung dienen die markanten Hintergrundfarben der aneinanderfolgenden Beiträge.`
				}
			]
		},

		{
			imgSrc: require('@/assets/img/ail/AIL-Mobile-Missing-01.jpg'),
			squareCorners: false,
			isWebsite: false,
			isMobileWebsite: true,
			task: 'Konzeption, Screendesign und Programmierung',
			projectName: 'AIL Webraum',
			elementDescription: '404 Seite',
			descriptionIsVisible: true,
			meta: [
				{
					type: 'text',
					text: `Die Fehlermeldungsseite 404 erscheint, wenn Besucher*innen einen Pfad ansteuert, der nicht gefunden werden kann.`
				},
				{
					type: 'text',
					text: `Für die 404-Seite des AIL wird ein Foto eines Talks über Schwarze Materie verwendet. Diese ist zwar wissenschaftlich messbar, kann aber – ähnlich wie der falsche Websitepfad – nicht gefunden werden.`
				},
				{
					type: 'text',
					text: `Je nach Screengröße zeigt der Pfeil des Home-Links auf ein anderes <i>›Zuhause‹</i> in der Milchstraße.`
				}
			]
		},
		// {
		// 	imgSrc: require('@/assets/img/ail/ail-detailpage-01.png'),
		// 	squareCorners: false,
		// 	isWebsite: true,
		// 	task: 'Konzeption, Screendesign und Programmierung',
		// 	projectName: 'AIL Webraum',
		// 	elementDescription: 'Detailseite Seite',
		// 	descriptionIsVisible: true,
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Hat ein Besucher des AIL Webraumen ein interessantes Thema gefunden, kann er dieses auf den fokusierten Detailseiten konsumieren.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Zur Orientierung und Differenzierung im Webraum sind die Detailseiten mit markanten Farben hinterelgt.`
		// 		}
		// 	]
		// },

		// {
		// 	videoSrc: require('@/assets/img/ail/ail-participate-events.mp4'),
		// 	imgSrc: require('@/assets/img/ail/ail-stack-01.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Konzeption, Screendesign und Programmierung',
		// 	projectName: 'AIL Website',
		// 	elementDescription: 'Video für Participate Seite',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `In animierten Varianten wird die Dynamik des Design durch die Bewegung der O’s verstärkt. Der Eindruck einer belebten Menschenansammlung entsteht.`
		// 		}
		// 	]
		// },
		// {
		// 	videoSrc: require('@/assets/img/ail/ail-participate-artists.mp4'),
		// 	imgSrc: require('@/assets/img/ail/ail-stack-01.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Konzeption, Screendesign und Programmierung',
		// 	projectName: 'AIL Website',
		// 	elementDescription: 'Video für Participate Seite',
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `In animierten Varianten wird die Dynamik des Design durch die Bewegung der O’s verstärkt. Der Eindruck einer belebten Menschenansammlung entsteht.`
		// 		}
		// 	]
		// },











		// // MUTTER VATERKARENZ KIND
		// {
		// 	imgSrc: require('@/assets/img/mutter_vaterkarenz_kind-01.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Buchkonzeption und -gestaltung',
		// 	projectName: 'MUTTER VATERKARENZ KIND',
		// 	elementDescription: 'Cover',
		// 	descriptionIsVisible: true,
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Karenz beschreibt nicht das Betreuen eines Kinder aus Sicht der Familie, sondern das Fernblieben der Arbeitskraft aus Sicht des Arbeitgebers.`
		// 		},
		// 		{
		// 			type: 'text',
		// 			text: `Auch sollte es keine Differenziereung zwischen Mutter- oder Vaterkarenz geben, doch es (noch) notwendig diese zu machen.`
		// 		},
		// 	]
		// },





		
		// // FRANCOPHIL
		// {
		// 	imgSrc: require('@/assets/img/francophil-01.png'),
		// 	squareCorners: false,
		// 	isWebsite: false,
		// 	task: 'Logovorschlag',
		// 	projectName: 'FRANCOPHIL',
		// 	elementDescription: 'Text für Fun Facts und Social Media.',
		// 	descriptionIsVisible: true,
		// 	meta: [
		// 		{
		// 			type: 'text',
		// 			text: `Teil von IVANs Marke sind spaßige Fakten, die die Notwendigkeite eines sportlichen Ausgleiches im Alltag verdeutlichen.`
		// 		},
		// 	]
		// }

		
	],
}

function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // eslint-disable-line no-param-reassign
    }
};

shuffleArray(imgStore.images);

export default imgStore;


