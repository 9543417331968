
<template>
  <article
    :class="{
      'comp-card' : true,
      'browser-dots' : imageData.isWebsite
    }"
  >
    <comp-image
      class="card-image"
      :imageData="imageData"
      @click.native="showMessageUps($event)"
    />

    <div
      v-if="imageData.projectName || imageData.task || imageData.elementDescription"
      class="card-details"
    >
      <span
        v-if="imageData.projectName"
        class="card-details-name"
      >{{ imageData.projectName }}</span><span v-if="imageData.task && imageData.projectName" style="font-weight: 800;">, </span><span v-if="imageData.task" class="card-details-task">{{ imageData.task }}</span>
      <br>

      <span
        v-if="imageData.elementDescription"
        class="card-details-description"
      >
        {{ imageData.elementDescription }}
      </span>
    </div>
  </article>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  data () {
    return {
      currentMetaMessage: 0
    }
  },

  props: [
    'imageData',
  ],

  methods: {
    showMessageUps(event) {
      let wiggleImage = anime({
        targets: event.target,
        rotate: [
          {
            value: '-1deg'
          },
          {
            value: '1deg'
          },
          {
            value: '-1deg'
          },
          {
            value: '0deg'
          },
        ],
        duration: 300,
        easing: 'easeInOutQuart',
      });

      const dataForParent = {
        lastClickX: event.pageX,
        lastClickY: event.pageY,
        currentUpsMessage: this.imageData.meta[this.currentMetaMessage],
        numberOfCurrentMessage: this.currentMetaMessage,
        totalNumberOfMessages: this.imageData.meta.length
      }

      this.$emit('childIsClicked', dataForParent)

      if (this.currentMetaMessage >= this.imageData.meta.length) {
        this.currentMetaMessage = 0
      } else {
        this.currentMetaMessage ++
      }
    }
  }
}

</script>

<style lang="scss">
	.comp-card {
		display: inline-block;
		width: 100%;
		box-sizing: border-box;
    position: relative;
		margin-bottom: var(--spacer-padding);

		/*Remove webkit tap highligh on touch devices*/
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		text-decoration: none;
		color: inherit;

		// &.browser-dots::after {
		// 	content: '•••';
		// 	font-size: 1.25em;
		// 	line-height: var(--browser-bar-height);
		// 	position: absolute;
		// 	z-index: 10;
		// 	color: white;
		// 	left: 5px;
		// 	top: calc(var(--browser-bar-height) * -0.15);
		// 	pointer-events: none;
		// }
	}

	.card-image {
		position: relative;
		overflow: hidden;

		border-radius: var(--border-radius);
		background-color: var(--gray-light);
		animation: animation-fade-in .5s ease;

		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		will-change: transform;

		user-select: none;
	}

	.card-details {
		margin: 8.5px 5px .5em 5px;
		letter-spacing: 0.025em;

    .card-details-task,
    .card-details-name {
      font-weight: 800;
    }
    
    .card-details-description {
      font-weight: 500;
    }
	}

	@keyframes animation-fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
</style>
