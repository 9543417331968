
<template>
    <section class="comp-call-to-action max-width">
        <div
            class="call-to-action package-section"
            style="--background-color: #FFB484"
        >
            <h3 class="typography-editorial-small">Noch Fragen?</h3>
            
            <div>
                <p>
                    Kein Problem!
                </p>
                <p>
                    Antworten auf häufige Fragen findest du in unseren Q&A.
                </p>
            </div>
            
            <a
                :href="`/kontakt#faq`"
                class="link-button"
            >Zu unseren Q&A</a>
        </div>
        
        <div
            class="call-to-action package-section"
            style="--background-color: #EBE7FA"
        >
            <h3 class="typography-editorial-small">Zusammenarbeit!</h3>
            
            <div>
                <p>
                    Danke für dein Vertrauen. Wir freuen uns sehr, dass du mit uns arbeiten möchtest.
                </p>
                <p>
                    Schreibe uns ein paar Zeilen, wir melden uns in kürze.
                </p>
            </div>
            
            <a
                :href="`mailto:${this.$store.state.mail}`"
                class="link-button"
            >Projektanfrage per Mail</a>
        </div>
    </section>
</template>

<script>
import CompAccordionSingleEntry from '../components/CompAccordionSingleEntry.vue'

export default {
  name: 'CompCallToAction'
}
</script>

<style lang="scss">
	@import '../assets/css/scss-variables.scss';

    .comp-call-to-action {
        display: grid;
        gap: var(--spacer-block-with-fallback);
        align-items: stretch;

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            text-align: center;
        }

        .link-button {
            margin-top: calc(var(--spacer-em-half) * 1.5);
        }

        p {
            hyphens: none;
        }

        @media (min-width: $mediaquery-tablet-landscape) {
            grid-template-columns: 1fr 1fr;
            gap: var(--column-gap);
            justify-content: center;
            
            & > * {
                padding: var(--padding-outside);
            }

            // & > div:nth-child(1) {
            //     grid-column: span 3;
            // }

            // & > div:nth-child(2) {
            //     grid-column: span 4;
            // }
        }
    }
</style>

