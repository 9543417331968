<template>
  <div class="page page-home">

    <section class="section">
      <CompHero
        id="hero"
        :propTextHero="passToHero.textHero"
        :propTextDescription="passToHero.textDescription"
        propHeadlineLevel="h1"
      />
    </section>

    <section class="section section-showcase columns-masonry max-width warum?">
      <comp-card
        v-for="imageData in importedImageStore.images"
        :imageData="imageData"
        :key="`image-${imageData.imgSrc}`"
        @childIsClicked="childIsClicked"
      />
    </section>

    <section
      id="ueber-uns"
      class="section section-text section-about package-section max-width"
      style="--background-color: lavenderblush;"
    >
      <h3 class="typography-editorial-big">
        Über uns:<br>
        Wir lieben Lösungen.
      </h3>

      <h3 class="typography-editorial-small">
        <p>
          Und Erfindungen. Und Probleme (<i>ohne Probleme keine Lösungen</i>), Herausforderungen, volle & leere Blätter (<i>und alles dazwischen</i>). Prozesse, Programme und Projekte.
        </p>

        <p>
          Wir machen: Kreativ-Beratung, Grafik-Design, Webseiten und Ähnliches.
        </p>

        <!-- <p>
          Wie sind gute Lösungen?: Sozial, nachhaltig, innovativ.
        </p> -->
      </h3>
    </section>

      <!-- <h3 class="typography-editorial-small">
        <p>
          Fast Egal welche (hauptsache nachhaltig), Ideen verändern die Welt. Leider bleibt so mancher Geistesblitz auf der Strecke…
        </p>

        <p>
          Wir vom Design Studio Eureka! begleiten deshalb Ideen auf ihrem Weg zum fertigen Projekte.
        </p>

        <p>
          Als Kreativ-Beartung födern wir junge Ideen und statten sie später mit Grafik-Designs und Webseiten aus, um sie und vollem Glanz zu präsentierne
        </p>
      </h3>
    </section>  -->

    <!-- <section
      id="kontakt"
      class="section section-text section-contact"
    >
      <h3 class="typography-editorial-big">
        Hast du ein Problem?<br>
        Kontakt:
      </h3>

      <div class="contact-button-holder">
        <a
          :href="`mailto:${computedMail}`"
          class="button bubble bubble-light"
        >{{ computedMail }}</a>
        <a
          :href="`tel:${computedPhone.number}`"
          class="button bubble bubble-light"
        >{{ computedPhone.layout }}</a>
      </div>
    </section> -->

		<CompCallToAction />

    <comp-ups-message
      v-for="message in upsMessages"
      :key="message.number"
      :index="message.number"
      :lastClickX="lastClickX"
      :lastClickY="lastClickY"
      :upsMessage="message"
    />

    <comp-footer />
  </div>
</template>

<script>
import imgStore from '@/store/imgStore.js'
import CompCard from '../components/CompCard.vue'
import CompFooter from '../components/CompFooter.vue'
import CompCallToAction from '../components/CompCallToAction.vue'
import CompHero from '../components/CompHero.vue'

import scssVariables from '@/assets/css/scss-variables.scss';

import { createSEOMeta } from "@/helpers/helperMetainfo.js";

const metaInfos = {
  title: `Design Studio Eureka!`,
  // description: `Wir vom Design Studio Eureka! laufen zwar nicht nackt durch die Straßen, erfinden und gestalten aber kreative Lösungen in den Kernbereichen Identität, Webseiten und Kreativberatung.`,
  // description: `Wir vom Design Studio Eureka! erfinden und (mit)gestalten Kreative Lösungen für Markenberatung, Grafik-Design und Webseiten.`,
  // description: `Wir vom Design Studio Eureka! erfinden und mitgestalten kreative Lösungen. Unsere Kernleistungen umfassen das erstellen von Markenidentität, Webseiten und die dazugehörige Kreativberatung.`
  // description: `Wir vom Design Studio Eureka! (mit)gestalten kreative Lösungen für Markenberatung, Identitäten und Webseiten.`,
  // description: `Design Studio Eureka! ist spezialisiert auf Kreativberatung, Identitätsbildung und Webdesign für Marken, die Wert auf einzigartige und kreative Lösungen legen.`,
  // description: `Design Studio Eureka! (mit)gestaltet kreative Lösungen in den Kernbereichen Markenberatung, Identitätsbildung und Webseiten.`,
  // description: `Design Studio Eureka! bietet Kreativberatung, Identitätsbildung und Webseiten für Marken mit Anspruch auf kreativen Lösungen.`,
  // description: `Das Design Studio Eureka! (mit)gestaltet Identitäten und Webseiten für Marken mit Anspruch auf kreative Lösungen.`,
  description: `Eureka! (mit)gestaltet Identitäten und Webseiten für Marken mit Anspruch auf kreative Lösungen.`,

  // title: `erfinden gute Lösungen.`,
  // title: `Wir laufen nicht nackt durch die Straßen, erfinden aber gute Lösungen.`,
  // title: `Wir sind nicht Archimedes, haben aber gute Lösungen.`,
  // title: `Wir sind nicht Archimedes, haben aber gute Lösungen.`,
  // title: `Design Eureka! Design für Ideen.`,
  // title: `Eureka! Design für Ideen.`,
  // title: `Eureka! Design beginnt mit (d)einer Idee.`,
  // title: `Design Studio Eureka! Wir lieben Lösungen.`,
  // title: `Kreative Lösungen für Markenberatung, Grafik-Design und Webseiten.`,
  // title: `Gemeinsam schaffen wir Lösungen für Markenberatung, Identitäten und Webseiten.`,
  // title: `Gemeinsam schaffen wir Lösungen für Markenberatung, Identitäten und Webseiten.`,

  image: ``,
  robots: `index, follow`
}

export default {
  components: {
    CompCard,
    CompFooter,
    CompHero,
    CompCallToAction
  },

  data() {
    return {
      importedImageStore: imgStore,
      upsMessagesNext: 0,
      upsMessagesDisplayed: 0,
      upsMessages: [],
      lastClickX: 0,
      lastClickY: 0,
      imageArrays: 1,
      passToHero: {
        textHero: [
          `Als Archimedes das Prinzip des statischen Auftriebs entdeckte, war er so begeistert, dass er nackt durch die Straßen rannte.`,
          `Das Wort, das er dabei rief ist bis heute ein Ausdruck für das Erfinden einer guten Lösung.*`
        ],
        textDescription: `*Wir laufen zwar nicht nackt durch die Straßen, haben aber auch ganz gute Lösungen:`
      }
    }
  },

  metaInfo: createSEOMeta(metaInfos.title, metaInfos.description, metaInfos.image, metaInfos.robots),

  computed: {
    computedPhone () {
      return this.$store.state.phone
    },

    computedMail () {
      return this.$store.state.mail
    }
  },

  methods: {
    childIsClicked (childData) {
      this.lastClickX = childData.lastClickX
      this.lastClickY = childData.lastClickY

      this.upsMessages.push({
        number: this.upsMessagesNext ++,
        currentUpsMessage: childData.currentUpsMessage,
        numberOfCurrentMessage: childData.numberOfCurrentMessage,
        totalNumberOfMessages: childData.totalNumberOfMessages
      });

      let timeTillDeleted = childData.currentUpsMessage ? 2500 + (childData.currentUpsMessage.text.length * 40) : 2000

      this.upsMessagesDisplayed ++

      setTimeout(() => {
        this.upsMessages.splice(0, 1);
      }, timeTillDeleted );
    }
  }
}

</script>

<style lang="scss">
  @import '../assets/css/scss-variables.scss';

  .section {
    & + .section {
      margin-top: var(--spacer-padding);
    }

    & + .section.section-contact {
      margin-top: calc(var(--spacer-padding) * 2);
    }

    &.section-showcase {
      margin-top: calc(var(--spacer-padding) * 1.5) !important;
    }

    &.section-text {
      text-align: center;

      & > * + * {
        margin-top: var(--spacer-padding);
      }
    }
  }

  *[id] {
    scroll-margin: var(--spacer);

    @media (pointer: fine) {
      scroll-margin: calc(var(--navigation-bar-height) + (var(--spacer) * 0.8));
    }

    @media (min-width: $mediaquery-tablet-landscape) {
      scroll-margin: calc(var(--navigation-bar-height) + (var(--spacer) * 0.8));
    }
  }

  .contact-button-holder {
    display: flex;
    gap: calc(var(--spacer) * .5);
    justify-content: center;
    flex-direction: column;

    @media (min-width: $mediaquery-tablet-portrait) {
      flex-direction: row;
    }

    a.button.bubble {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      border-width: 0;
      font-weight: 800;
      font-size: var(--font-size-headline-bodytext);
      color: inherit;

      @media (max-width: $mediaquery-tablet-portrait) {
        max-width: unset;
      }

      --gray-button-background: rgba(230, 230, 230);
      --gray-button-background-active: rgba(220, 220, 220);
      --gray-button-border: rgba(210, 210, 210);
      --button-depth: .25em;
      background-color: var(--gray-button-background);
      border-style: unset;
      border-width: unset;
      border-color: unset;
      box-shadow: 0px 0em 0px var(--gray-button-border);
      transition:
        box-shadow         var(--transition-speed) var(--transition-easing),
        transform          var(--transition-speed) var(--transition-easing),
        background-color   var(--transition-speed) var(--transition-easing),
        color              var(--transition-speed) var(--transition-easing);

      @media (hover: hover) {
        &:hover {
          box-shadow: 0px calc(var(--button-depth) * 1) var(--gray-button-border);
          transform: translateY(calc(var(--button-depth) * -1));
        }
      }

      &:active {
        box-shadow: 0px calc(var(--button-depth) * 0) var(--gray-button-border);
        transform: translateY(0em);
        background-color: var(--gray-button-background-active);
      }
    }
  }

</style>
